import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

type RevenueRanking = {
  source: string;
  storeId: string;
  sellerId: string;
  campaignId: string;
  lookback: number;
};

export const getRevenueMetrics = ({ source, storeId, sellerId, campaignId, lookback }: RevenueRanking) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const { startDate } = store.getters.dates;
  const { endDate } = store.getters.dates;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: 'oto/ranking/getRevenueMetrics',
    params: {
      brand: brand.id,
      startDate,
      endDate,
      source,
      storeId,
      sellerId,
      campaignId,
      lookback,
    },
  });
};

export const getRevenue = ({ source, storeId, sellerId, campaignId, lookback }: RevenueRanking) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const { startDate } = store.getters.dates;
  const { endDate } = store.getters.dates;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: 'oto/ranking/getRevenue',
    params: {
      brand: brand.id,
      startDate,
      endDate,
      source,
      storeId,
      sellerId,
      campaignId,
      lookback,
    },
  });
};
