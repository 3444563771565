import { TableParams, TotalizersResponse } from '../models';
import { getTableTotalizers } from '@/features/SalesReportNew/services';

export const fetchTotalizers = ({
  preset,
  query,
  campaignId,
  storeId,
  sellerId,
  cluster,
  originSale,
  contactType,
}: TableParams) =>
  getTableTotalizers({
    preset,
    query,
    campaignId,
    storeId,
    sellerId,
    cluster,
    originSale,
    contactType,
  }).then(response => formatResponse(response?.data));

const formatResponse = (response: TotalizersResponse): TotalizersResponse => {
  return {
    success: response?.success,
    errors: response?.errors,
    data: response?.data,
  };
};
