<template>
  <section id="audiences-stats">
    <div class="container">
      <div class="cols d-flex align-items-center">
        <div class="count">
          <oto-score :loading="loadingCount" v-if="otoScoreIsEnabled" />
          <audience-count
            :loading-count="loadingCount"
            :people="people"
            :time="time"
            :is-view="isView"
            :oto-score-is-enabled="otoScoreIsEnabled"
          />
        </div>
        <div v-if="!isView" class="audience-steps">
          <audience-steps :selected-step="selectedStep" :change-step="changeStep" type="simple" />
        </div>
        <stats-buttons
          :selected-step="selectedStep"
          :change-step="changeStep"
          :people="people"
          :is-view="isView"
          data-cy="audience-stats-button"
        />
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment';
import bus from '@/helpers/events/bus';
import AudienceCount from '@/components/audiences/create/stats/AudienceCount';
import AudienceStepsController from '@/components/audiences/create/AudienceStepsController';
import AudienceStatsButtons from '@/components/audiences/create/stats/AudienceStatsButtons';
import Otometro from '@/features/Otometro/components/Otometro.vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import store from '@/store';

export default {
  components: {
    'audience-count': AudienceCount,
    'oto-score': Otometro,
    'audience-steps': AudienceStepsController,
    'stats-buttons': AudienceStatsButtons,
  },
  data() {
    return {
      updated: false,
      lastUpdate: moment(),
      time: 0,
    };
  },
  computed: {
    ...mapGetters('audience', ['isView']), // -> this.isView
    ...mapState('audience', [
      'selectedStep', // -> this.selectedStep
      'people', // -> this.people
      'loadingCount',
    ]),
    otoScoreIsEnabled() {
      return store?.getters?.getEnabledOtoScore && this.$route.meta.title !== 'view-audience';
    },
  },
  mounted() {
    setInterval(() => {
      if (!this.updated) {
        const time = moment().diff(this.lastUpdate) / 1000;
        if (time > 5) this.time = time;
      } else {
        this.time = 0;
      }
      this.updated = false;
    }, 1000);

    bus.$on('count-updated', () => {
      this.updated = true;
      this.lastUpdate = moment();
      this.time = 0;
    });
  },
  methods: {
    ...mapActions('audience', [
      'changeStep', // -> this.changeStep()
    ]),
  },
};
</script>

<style lang="scss" scoped>
#audiences-stats {
  padding: 18px 0 10px;
  background-color: $color-white;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  color: $color-white;
  z-index: 98;

  .cols {
    max-width: 99%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .count {
      flex-grow: 10;
      display: flex;
    }
    .audience-steps {
      flex-grow: 1;
      justify-content: center;
      display: flex;
    }

    @media (max-width: $md-max-width) {
      flex-wrap: wrap;
    }
  }
}
</style>
