<template>
  <div class="expansion-options">
    <div class="label">
      {{ $t('audiences.expansions.sub-title') }}
      <span class="adicional-value"> (+ {{ returnLookALikeValue() }}) </span>
    </div>
    <div class="input-wrapper">
      <span>{{ $t('audiences.expansions.similar') }}</span>
      <form>
        <div class="form-group">
          <input
            id="formControlRange"
            v-model="range"
            type="range"
            class="form-control-range"
            min="0"
            max="100"
            step="10"
            @input="inputRange()"
          />
          <ul>
            <li v-for="(item, index) in itemRangePercent" :key="item">{{ index }}%</li>
          </ul>
        </div>
      </form>
      <span>{{ $t('audiences.expansions.broad') }}</span>
    </div>
    <p class="row" v-html="$t('audiences.expansions.description')" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { formatDecimal, formatNumber } from '@/utilities/formatters';

export default {
  name: 'AudienceExpansionOptions',
  props: {
    objExpansion: {
      type: Object,
      default: () => {},
    },
    isExpansionActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemRangePercent: 11,
      value: 0,
      marginLeft: 10,
      maxRange: false,
      minRange: false,
      range: 0,
    };
  },
  watch: {
    range(newVal) {
      this.updateRange(newVal);
    },
  },
  methods: {
    returnLookALikeValue() {
      if (Number(this.range) === 0) {
        this.expansionLookALike(Number(this.range / 10));
        return 0;
      } else {
        if (this.objExpansion[Number(this.range / 10)] >= 1000) {
          this.expansionLookALike(Number(this.range / 10));
          return formatDecimal(this.objExpansion[Number(this.range / 10)], 1);
        }
        this.expansionLookALike(Number(this.range / 10));
        return this.objExpansion[Number(this.range / 10)];
      }
    },
    inputRange() {
      const styleEl = document.querySelector('#audience-expansion style');
      const input = document.querySelector('input[type=range]');
      this.range < 10
        ? (this.value = parseInt(this.updateRange(this.range + 10)))
        : (this.value = parseInt(this.updateRange(this.range - 10)));

      input.addEventListener(
        'input',
        function () {
          styleEl.textContent = `
          #formControlRange::-webkit-slider-runnable-track{background-size: ${formatNumber(this.value, '% 0')} 100%};
          #formControlRange::-moz-range-track{background-size: ${formatNumber(this.value, '% 0')} 100%};
          `;
        },
        false,
      );
    },
    ...mapActions('audience', [
      'expansionLookALike', // -> this.expansionLookALike()
      'updateRange',
    ]),
  },
  updated() {
    if (!this.isExpansionActive) {
      this.range = 0;
      this.value = 0;
      document.querySelector('#audience-expansion style').innerHTML = '';
    }
  },
  mounted() {
    // find input form group and append the style tag to create the blue bar
    const globalEl = document.querySelector('#audience-expansion .form-group');
    globalEl.append(document.createElement('style'));
    this.inputRange(this.range);
  },
};
</script>

<style lang="scss" scoped>
.expansion-options {
  background: $color-white;
  border-radius: 20px;
  display: block;
  height: 215px;
  padding: 25px 40px;
  width: 100%;

  .adicional-value {
    margin-left: 3px;
    font-weight: 600;
    color: $gray-500;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 80px;
    margin-bottom: 20px;
    width: 100%;
  }

  form {
    height: 25px;
  }

  span {
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: $gray-700;
  }

  input[type='range'] {
    -webkit-appearance: none;
    height: 25px;
    margin: 0 auto;
    width: 98% !important;
    padding: 0 !important;
    border: 0 !important;
  }

  ul {
    list-style: none;
    padding-inline-start: 0px;
    display: flex;

    li {
      text-align: center;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: $gray-700;
      margin-top: 5px;
    }

    li:first-child {
      padding: 0 0 0 8px !important;
    }

    li:nth-child(n + 1):nth-child(-n + 9) {
      padding: 0 0 0 (64.5px - 2);
    }

    li:nth-child(n + 8):nth-child(-n + 11) {
      padding: 0 0 0 (62.5px - 2);
    }
  }

  p {
    width: 90%;
    margin: 0px 45px;
    text-align: center;
    color: $gray-600;
    font-size: 11px;
  }
}
</style>
