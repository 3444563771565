import store from '@/store';

/**
 * @description Checks if the client has the sub route.
 */
export const hasMatchSubRoutes = (routeId, subRouteId) => {
  const routesGroup = store.getters?.getRoutes.find(route => route.id === routeId)?.routes;
  if (!routesGroup) return false;
  const verifyId = routesGroup.find(route => route.id === subRouteId)?.id;

  return verifyId === subRouteId;
};

export const validateAudienceActivationRoute = activationRouteId => {
  const audienceManagementRoute = store.getters?.getRoutes.find(route => route.id === 'audience-mgmt');
  const isAudienceActivationRouteValid = audienceManagementRoute?.routes.some(
    subRoute => subRoute.id === activationRouteId,
  );
  return !!isAudienceActivationRouteValid;
};

export const validateCampaignActivationRoute = activationRouteId => {
  const campaignManagementRoute = store.getters?.getRoutes.find(route => route.id === '360');
  const isCampaignActivationRouteValid = campaignManagementRoute?.routes.some(
    subRoute => subRoute.id === activationRouteId,
  );
  return !!isCampaignActivationRouteValid;
};

/**
 * @description Checks if the client has the route.
 */
export const hasMatchRoutes = routeId => Boolean(store.getters?.getRoutes.find(route => route.id === routeId)?.id);
