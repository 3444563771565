// libs, helpers, services
import { createRouter, createWebHistory } from 'vue-router';
import { hasMatchRoutes, hasMatchSubRoutes } from '@/helpers/verify-routes';
import store from '@/store';
// auth
import LoginOto from '@/views/LoginOto';
import Layout from '@/views/Layout';
// welcome
import Welcome from '@/views/Welcome';
// dashboard
import Dashboard from '@/views/Dashboard';
import DashboardSms from '@/views/DashboardSms';
import DashboardPush from '@/views/DashboardPush';
import DashboardWebPush from '@/views/DashboardWebPush';
import FullView from '@/views/FullView';
import CustomerDataPlatform from '@/views/CustomerDataPlatform';
// audience
import Audience from '@/views/Audience';
import AudienceManagement from '@/views/AudienceManagement';
import AudiencesList from '@/views/AudiencesList';
// campaigns
import Campaigns from '@/views/Campaigns';
import CampaignsDetails from '@/views/CampaignsDetails';
import CampaignsManagementNew from '@/views/CampaignsManagement';
// products
import Product from '@/views/Product';
import ProductsDashboard from '@/views/ProductsDashboard';
import ProductsList from '@/views/ProductsList';
import Category from '@/views/Category';
// reports
import Reports from '@/views/Reports';
import ReportsList from '@/views/ReportsList';
// glossary
import Glossary from '@/views/Glossary';
// rockintags
import RockinTags from '@/views/RockinTags';
// NPS
import NpsAnalytics from '@/views/NpsAnalytics';
import NpsCampaigns from '@/views/NpsCampaigns';
// oto
import Customers from '@/views/oto/Customers';
import RegisterCustomer from '@/views/oto/RegisterCustomer';
import CustomerView from '@/views/oto/CustomerView';
import CampaignsManagement from '@/views/oto/CampaignsManagement';
import CampaignsManagementEdit from '@/views/oto/CampaignsManagementEdit';
import IndicatorsOld from '@/views/oto/Indicators-old';
import Indicators from '@/views/oto/Indicators';
import EngagementReport from '@/views/oto/EngagementReport';
import Sellers from '@/views/oto/Sellers';
import PushNotifications from '@/views/oto/PushNotifications';
import PushNotificationsDetail from '@/views/oto/PushNotificationsDetail';
import ActionsAttachments from '@/views/oto/ActionsAttachments';
import ActionsMessages from '@/views/oto/ActionsMessages';
import CashbackDetail from '@/views/oto/CashbackDetail';
import CashbackReport from '@/views/oto/CashbackReport';
import SalesReport from '@/views/oto/SalesReport';
import CustomerDistribution from '@/views/CustomerDistribution';
import CustomerDistributionEdit from '@/views/CustomerDistributionEdit';
import RegistrationCulture from '@/views/RegistrationCulture';
import SalesReportNew from '@/views/oto/SalesReportNew.vue';

// settings
import Settings from '@/views/Settings';
// settings components
import Metrics from '@/components/settings/Metrics';
import Security from '@/components/settings/Security';
import Preferences from '@/components/settings/Preferences';
import Users from '@/components/settings/Users';
import Integrations from '@/components/settings/Integrations';
import Limits from '@/components/settings/Limits';
import IntegrationCreateEdit from '@/components/settings/integrations/IntegrationCreateEdit';
import StoreSettings from '@/components/settings/stores/StoreSettings.vue';
import StoreSettingsTable from '@/components/settings/stores/StoreSettingsTable';
import StoresSettingsRegister from '@/components/settings/stores/StoresSettingsRegister';
import CustomFields from '@/components/settings/customFields/CustomFields';
// stores
import Stores from '@/views/Stores';
// labels
import Labels from '@/views/Labels';
import LabelDetail from '@/views/LabelDetail';
import LabelsAi from '@/views/LabelsAi';
import LabelsCustom from '@/views/LabelsCustom';
// personas
import Personas from '@/views/Personas';
import PersonasDetail from '@/views/PersonasDetail';
import PersonasDashboard from '@/views/PersonasDashboard';
import PersonasCompare from '@/views/PersonasCompare';
// history
import HistoryImport from '@/views/HistoryImport';
import HistoryExport from '@/views/HistoryExport';
import RawDataAccess from '@/views/RawDataAccess';
import DataIngestionHistory from '@/views/DataIngestionHistory';
import DataInconsistency from '@/views/DataInconsistency';
// Oto Academy
import LandingPageOtoAcademy from '@/views/LandingPageOtoAcademy';

const routes = [
  {
    path: '/',
    name: '',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Welcome',
        component: Welcome,
        meta: {
          title: 'welcome',
        },
      },
      {
        path: 'oto-academy',
        name: 'oto-academy',
        component: LandingPageOtoAcademy,
        meta: {
          title: 'oto-academy',
          breadcrumb: [{ name: 'oto-academy', link: '/oto-academy' }],
        },
      },
      // Start analytics
      {
        path: 'analytics/o2o',
        name: 'analytics-o2o',
        component: FullView,
        meta: {
          title: 'analytics-o2o',
          breadcrumb: [{ name: 'analytics' }, { name: 'analytics-o2o', link: '/analytics/o2o' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('analytics', 'analytics-o2o')) next();
          else next('/');
        },
      },
      // End analytics
      // Start products
      {
        path: 'products',
        name: 'analytics-products',
        component: ProductsDashboard,
        meta: {
          title: 'analytics-products',
          breadcrumb: [{ name: 'analytics' }, { name: 'products', link: '/products' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('analytics', 'analytics-products')) next();
          else next('/');
        },
      },
      {
        path: 'products/search',
        name: 'productsList',
        component: ProductsList,
        props: route => ({ term: route.query.term }),
        meta: {
          title: 'products',
          breadcrumb: [{ name: 'analytics' }, { name: 'products', link: '/products' }, { name: 'products-search' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchRoutes('products') || hasMatchSubRoutes('analytics', 'analytics-products')) next();
          else next('/');
        },
      },
      {
        path: 'products/:id',
        name: 'product',
        component: Product,
        meta: {
          title: 'products',
          breadcrumb: [{ name: 'analytics' }, { name: 'products', link: '/products' }, { name: 'product-detail' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchRoutes('products') || hasMatchSubRoutes('analytics', 'analytics-products')) next();
          else next('/');
        },
      },
      {
        path: 'products/categories/:name',
        name: 'category',
        component: Category,
        meta: {
          title: 'products',
          breadcrumb: [{ name: 'analytics' }, { name: 'products', link: '/products' }, { name: 'category-detail' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchRoutes('products') || hasMatchSubRoutes('analytics', 'analytics-products')) next();
          else next('/');
        },
      },
      // End products
      // Start store
      {
        path: 'stores',
        name: 'analytics-stores',
        component: Stores,
        meta: {
          title: 'analytics-stores',
          breadcrumb: [{ name: 'analytics' }, { name: 'stores', link: '/stores' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('analytics', 'analytics-stores')) next();
          else next('/');
        },
      },
      // End store
      // Start audience v1
      {
        path: '/audiences',
        name: 'audiences-activation',
        component: AudiencesList,
        props: route => ({ t: route.query.page }),
        meta: {
          title: 'audiences',
          breadcrumb: [{ name: 'audience-mgmt' }, { name: 'audience.list', link: '/audiences' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('audience-mgmt', 'audiences-activation')) next();
          else next('/');
        },
      },
      {
        path: '/new-audiences',
        name: 'new-audience-activation',
        component: AudienceManagement,
        props: route => ({ t: route.query.page }),
        meta: {
          title: 'audiences',
          breadcrumb: [{ name: 'audience-mgmt' }, { name: 'audience.list', link: '/new-audiences' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('audience-mgmt', 'new-audience-activation')) next();
          else next('/');
        },
      },
      {
        path: 'audiences/create',
        name: 'AudienceCreateOld',
        component: Audience,
        props: true,
        meta: {
          title: 'create-audience',
          breadcrumb: [
            { name: 'audience-mgmt' },
            { name: 'audience.list', link: '/audiences' },
            { name: 'audience.create' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('audience-mgmt', 'audiences-activation')) next();
          else next('/');
        },
      },
      {
        path: 'new-audiences/create',
        name: 'AudienceCreate',
        component: Audience,
        props: true,
        meta: {
          title: 'create-audience',
          breadcrumb: [
            { name: 'audience-mgmt' },
            { name: 'audience.list', link: '/new-audiences' },
            { name: 'audience.create' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('audience-mgmt', 'new-audience-activation')) next();
          else next('/');
        },
      },
      {
        path: 'audiences/create/:id',
        name: 'AudienceDuplicateOld',
        component: Audience,
        props: true,
        meta: {
          title: 'duplicate-audience',
          breadcrumb: [
            { name: 'audience-mgmt' },
            { name: 'audience.list', link: '/audiences' },
            { name: 'audience.duplicate' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('audience-mgmt', 'audiences-activation')) next();
          else next('/');
        },
      },
      {
        path: 'new-audiences/create/:id',
        name: 'AudienceDuplicate',
        component: Audience,
        props: true,
        meta: {
          title: 'duplicate-audience',
          breadcrumb: [
            { name: 'audience-mgmt' },
            { name: 'audience.list', link: '/new-audiences' },
            { name: 'audience.duplicate' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('audience-mgmt', 'new-audience-activation')) next();
          else next('/');
        },
      },
      {
        path: 'audiences/view/:creator/:id',
        name: 'AudienceDetailOld',
        component: Audience,
        props: true,
        meta: {
          title: 'view-audience',
          breadcrumb: [
            { name: 'audience-mgmt' },
            { name: 'audience.list', link: '/audiences' },
            { name: 'audience.detail' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('audience-mgmt', 'audiences-activation')) next();
          else next('/');
        },
      },
      {
        path: 'new-audiences/view/:creator/:id',
        name: 'AudienceDetail',
        component: Audience,
        props: true,
        meta: {
          title: 'view-audience',
          breadcrumb: [
            { name: 'audience-mgmt' },
            { name: 'audience.list', link: '/new-audiences' },
            { name: 'audience.detail' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('audience-mgmt', 'new-audience-activation')) next();
          else next('/');
        },
      },
      // End audience v1
      // Start labels
      {
        path: 'labels',
        name: 'audiences-labels',
        component: Labels,
        meta: {
          title: 'audiences-labels',
          breadcrumb: [{ name: 'audience-mgmt' }, { name: 'labels.list', link: '/labels' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('audience-mgmt', 'audiences-labels')) next();
          else next('/');
        },
      },
      {
        path: 'labels/create',
        name: 'LabelCreate',
        component: LabelDetail,
        props: {
          mode: 'create',
        },
        meta: {
          title: 'create-label',
          breadcrumb: [{ name: 'audience-mgmt' }, { name: 'labels.list', link: '/labels' }, { name: 'labels.create' }],
        },
      },
      {
        path: 'labels/create/:id',
        name: 'LabelsDuplicate',
        component: LabelDetail,
        props: route => ({ mode: 'create', id: route.params.id }),
        meta: {
          title: 'duplicate-label',
          breadcrumb: [
            { name: 'audience-mgmt' },
            { name: 'labels.list', link: '/labels' },
            { name: 'labels.duplicate' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('audience-mgmt', 'audiences-labels')) next();
          else next('/');
        },
      },
      {
        path: 'labels/view/:id',
        name: 'LabelView',
        component: LabelDetail,
        props: route => ({ mode: 'view', id: route.params.id }),
        meta: {
          title: 'view-label',
          breadcrumb: [{ name: 'audience-mgmt' }, { name: 'labels.list', link: '/labels' }, { name: 'labels.detail' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('audience-mgmt', 'audiences-labels')) next();
          else next('/');
        },
      },
      {
        path: 'labels/ai',
        name: 'LabelsAi',
        component: LabelsAi,
        meta: {
          title: 'ai-label',
          breadcrumb: [{ name: 'audience-mgmt' }, { name: 'labels.list', link: '/labels' }, { name: 'labels.ai' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('audience-mgmt', 'audiences-labels')) next();
          else next('/');
        },
      },
      {
        path: 'labels/custom',
        name: 'LabelsCustom',
        component: LabelsCustom,
        meta: {
          title: 'custom-label',
          breadcrumb: [{ name: 'audience-mgmt' }, { name: 'labels.list', link: '/labels' }, { name: 'labels.custom' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('audience-mgmt', 'audiences-labels')) next();
          else next('/');
        },
      },
      // End Labels
      // Start Personas
      {
        path: 'personas',
        name: 'audiences-personas',
        component: Personas,
        meta: {
          title: 'audiences-personas',
          breadcrumb: [{ name: 'audience-mgmt' }, { name: 'personas.list', link: '/personas' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('audience-mgmt', 'audiences-personas')) next();
          else next('/');
        },
      },
      {
        path: 'personas/view/:id',
        name: 'PersonaView',
        component: PersonasDetail,
        props: route => ({ mode: 'view', id: route.params.id }),
        meta: {
          title: 'view-persona',
          breadcrumb: [
            { name: 'audience-mgmt' },
            { name: 'personas.list', link: '/personas' },
            { name: 'personas.detail' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('audience-mgmt', 'audiences-personas')) next();
          else next('/');
        },
      },
      {
        path: 'personas/compare',
        name: 'audiences-personas-compare',
        component: PersonasCompare,
        meta: {
          title: 'personas-compare',
          breadcrumb: [
            { name: 'audience-mgmt' },
            { name: 'personas.list', link: '/personas' },
            { name: 'personas.compare' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('audience-mgmt', 'audiences-personas-compare')) next();
          else next('/');
        },
      },
      {
        path: 'personas/:id/compare',
        name: 'personasCompareId',
        props: route => ({ id: route.params.id }),
        component: PersonasCompare,
        meta: {
          title: 'personas-compare',
          breadcrumb: [
            { name: 'audience-mgmt' },
            { name: 'personas.list', link: '/personas' },
            { name: 'personas.compare' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('audience-mgmt', 'audiences-personas')) next();
          else next('/');
        },
      },
      {
        path: 'personas/create',
        name: 'PersonaCreate',
        component: PersonasDetail,
        props: {
          mode: 'create',
        },
        meta: {
          title: 'create-persona',
          breadcrumb: [
            { name: 'audience-mgmt' },
            { name: 'personas.list', link: '/personas' },
            { name: 'personas.create' },
          ],
        },
      },
      {
        path: 'personas/create/:id',
        name: 'PersonaDuplicate',
        component: PersonasDetail,
        props: route => ({ mode: 'create', id: route.params.id }),
        meta: {
          title: 'duplicate-persona',
          breadcrumb: [
            { name: 'audience-mgmt' },
            { name: 'personas.list', link: '/personas' },
            { name: 'personas.duplicate' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('audience-mgmt', 'audiences-personas')) next();
          else next('/');
        },
      },
      {
        path: 'personas/:id/dashboard',
        name: 'personasDashboard',
        component: PersonasDashboard,
        meta: {
          title: 'painel-persona',
          breadcrumb: [
            { name: 'audience-mgmt' },
            { name: 'personas.list', link: '/personas' },
            { name: 'personas.dashboard' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('audience-mgmt', 'audiences-personas')) next();
          else next('/');
        },
      },
      // End personas
      // Start campaigns
      {
        path: 'performance/campaigns',
        name: 'performance-audiences',
        component: Campaigns,
        props: route => ({ t: route.query.page }),
        meta: {
          title: 'campaigns',
          breadcrumb: [{ name: 'performance' }, { name: 'campaign.list', link: '/performance/campaigns' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('performance', 'performance-audiences')) next();
          else next('/');
        },
      },
      {
        path: 'performance/campaigns/create',
        name: 'campaignsCreate',
        component: Campaigns,
        meta: { title: 'campaigns', isModal: true },
      },
      {
        path: 'performance/campaigns/:id',
        name: 'campaignsDetails',
        component: CampaignsDetails,
        props: true,
        meta: {
          title: 'campaigns',
          breadcrumb: [
            { name: 'performance' },
            { name: 'campaign.list', link: '/performance/campaigns' },
            { name: 'campaign.detail' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('performance', 'performance-audiences')) next();
          else next('/');
        },
      },
      // End campaigns
      // Start performance
      {
        path: 'performance/email',
        name: 'performance-email',
        component: Dashboard,
        meta: {
          title: 'performance-email',
          breadcrumb: [{ name: 'performance' }, { name: 'performance-email', link: '/performance/email' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('performance', 'performance-email')) next();
          else next('/');
        },
      },
      {
        path: 'performance/sms',
        name: 'performance-sms',
        component: DashboardSms,
        meta: {
          title: 'performance-sms',
          breadcrumb: [{ name: 'performance' }, { name: 'performance-sms', link: '/performance/sms' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('performance', 'performance-sms')) next();
          else next('/');
        },
      },
      {
        path: 'performance/push',
        name: 'performance-push',
        component: DashboardPush,
        meta: {
          title: 'performance-push',
          breadcrumb: [{ name: 'performance' }, { name: 'performance-push', link: '/performance/push' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('performance', 'performance-push')) next();
          else next('/');
        },
      },
      {
        path: 'performance/tags',
        name: 'performance-tags',
        component: RockinTags,
        meta: {
          title: 'performance-tags',
          breadcrumb: [{ name: 'performance' }, { name: 'performance-tags', link: '/performance/tags' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('performance', 'performance-tags')) next();
          else next('/');
        },
      },
      {
        path: 'performance/webpush',
        name: 'performance-webpush',
        component: DashboardWebPush,
        meta: {
          title: 'performance-webpush',
          breadcrumb: [{ name: 'performance' }, { name: 'performance-webpush', link: '/performance/webpush' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('performance', 'performance-webpush')) next();
          else next('/');
        },
      },
      // End performance
      // Start cdp / integrations
      {
        path: 'connect/dashboard',
        name: 'connect-dashboard',
        component: CustomerDataPlatform,
        meta: {
          title: 'connect-dashboard',
          breadcrumb: [{ name: 'integrations.title' }, { name: 'integrations.dashboard' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('integrations', 'connect-dashboard')) next();
          else next('/');
        },
      },
      {
        path: 'connect/import',
        name: 'connect-import',
        component: HistoryImport,
        meta: {
          title: 'connect-import',
          breadcrumb: [{ name: 'integrations.title' }, { name: 'integrations.connect-import' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('integrations', 'connect-import')) next();
          else next('/');
        },
      },
      {
        path: 'connect/export',
        name: 'connect-export',
        component: HistoryExport,
        meta: {
          title: 'connect-export',
          breadcrumb: [{ name: 'integrations.title' }, { name: 'integrations.connect-export' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('integrations', 'connect-export')) next();
          else next('/');
        },
      },
      {
        path: 'connect/raw-data-access',
        name: 'connect-raw-data-access',
        component: RawDataAccess,
        meta: {
          title: 'connect-raw-data-access',
          breadcrumb: [{ name: 'integrations.title' }, { name: 'integrations.connect-raw-data-access' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('integrations', 'connect-raw-data-access')) next();
          else next('/');
        },
      },
      {
        path: 'connect/data-ingestion-history',
        name: 'connect-data-ingestion-history',
        component: DataIngestionHistory,
        meta: {
          title: 'connect-data-ingestion-history',
          breadcrumb: [{ name: 'integrations.title' }, { name: 'integrations.connect-data-ingestion-history' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('integrations', 'connect-data-ingestion-history')) next();
        },
      },
      {
        path: 'connect/data-inconsistency',
        name: 'connect-data-inconsistency',
        component: DataInconsistency,
        meta: {
          title: 'connect-data-inconsistency',
          breadcrumb: [{ name: 'integrations.title' }, { name: 'integrations.connect-data-inconsistency' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('integrations', 'connect-data-inconsistency')) next();
          else next('/');
        },
      },
      // End cdp / integrations
      // Start Reports
      {
        path: 'reports',
        name: 'reports',
        component: ReportsList,
        meta: {
          title: 'reports',
          breadcrumb: [{ name: 'reports.list', link: '/reports' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchRoutes('reports')) next();
          else next('/');
        },
      },
      {
        path: 'reports/:id',
        name: 'reportDetail',
        component: Reports,
        meta: {
          title: 'reports',
          breadcrumb: [{ name: 'reports.list', link: '/reports' }, { name: 'reports.detail' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchRoutes('reports')) next();
          else next('/');
        },
      },
      // End Reports
      // Start NPS
      {
        path: 'nps/analytics',
        name: 'nps-analytics',
        component: NpsAnalytics,
        props: false,
        meta: {
          title: 'nps-analytics',
          breadcrumb: [{ name: 'nps.index' }, { name: 'nps.analytics', link: '/nps/analytics' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('nps', 'nps-analytics')) next();
          else next('/');
        },
      },
      {
        path: 'nps/campaigns',
        name: 'nps-campaigns',
        component: NpsCampaigns,
        meta: {
          title: 'nps-campaigns',
          breadcrumb: [{ name: 'nps.index' }, { name: 'nps.campaigns', link: '/nps/campaigns' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('nps', 'nps-campaigns')) next();
          else next('/');
        },
      },
      // End NPS
      // Start oto
      {
        path: 'oto/customers',
        name: '360-customers',
        component: Customers,
        props: false,
        meta: {
          title: '360-customers',
          breadcrumb: [{ name: '360.index' }, { name: '360.customers', link: '/oto/customers' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', '360-customers')) next();
          else next('/');
        },
      },
      {
        path: 'oto/customers/create',
        name: '360-register-customer',
        component: RegisterCustomer,
        props: false,
        meta: {
          title: '360-register-customer',
          breadcrumb: [{ name: '360.index' }, { name: '360.register-customer', link: '/oto/register-customer' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', '360-customers')) next();
          else next('/');
        },
      },
      {
        path: 'oto/customers/search',
        name: '360-search',
        component: Customers,
        props: route => ({ t: route.query.t }),
        meta: {
          title: '360-customers',
          breadcrumb: [
            { name: '360.index', link: '/oto/customers' },
            { name: '360.customers', link: '/oto/customers' },
            { name: '360.search-results', link: '/oto/customers/search' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', '360-customers')) next();
          else next('/');
        },
      },
      {
        path: 'oto/customers/customerview/:id?',
        name: '360-customerviewId',
        component: CustomerView,
        props: true,
        meta: {
          title: '360-customers-view',
          breadcrumb: [
            { name: '360.index' },
            { name: '360.customers', link: '/oto/customers' },
            { name: '360.profile', link: '/oto/customers' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', '360-customers')) next();
          else next('/');
        },
      },
      {
        path: 'oto/registration-culture',
        name: 'oto-registration-culture',
        component: RegistrationCulture,
        meta: {
          title: 'oto-registration-culture',
          breadcrumb: [{ name: '360.index' }, { name: '360.oto-registration-culture' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', 'oto-registration-culture')) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'oto/customer-distribution',
        name: 'customer-distribution',
        component: CustomerDistribution,
        meta: {
          title: 'oto-customer-distribution',
          breadcrumb: [{ name: '360.index' }, { name: '360.oto-customer-distribution' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', 'customer-distribution')) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'oto/customer-distribution/:id',
        name: 'CustomerDistributionEdit',
        component: CustomerDistributionEdit,
        props: route => ({ id: route.params.id }),
        meta: {
          title: 'oto-customer-distribution-edit',
          breadcrumb: [
            { name: '360.index' },
            { name: '360.oto-customer-distribution', link: '/oto/customer-distribution' },
            { name: '360.oto-customer-distribution-edit' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', 'customer-distribution')) {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: 'oto/actions',
        name: 'campaign-new',
        component: CampaignsManagementNew,
        meta: {
          title: 'campaign-new',
          breadcrumb: [{ name: '360.index' }, { name: '360.campaigns.new-title', link: '/oto/actions' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', 'campaign-new')) next();
          else next('/');
        },
      },
      {
        path: 'oto/campaigns',
        name: '360-campaigns',
        component: CampaignsManagement,
        meta: {
          title: '360-campaigns',
          breadcrumb: [{ name: '360.index' }, { name: '360.campaigns.title', link: '/oto/campaigns' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', '360-campaigns')) next();
          else next('/');
        },
      },
      {
        path: 'oto/campaigns/create',
        name: 'oto-actions-create-old',
        component: CampaignsManagementEdit,
        props: { mode: 'create' },
        meta: {
          title: '360-campaigns',
          breadcrumb: [
            { name: '360.index' },
            { name: '360.campaigns.title', link: '/oto/campaigns' },
            { name: '360.campaigns.create' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', '360-campaigns')) next();
          else next('/');
        },
      },
      {
        path: 'oto/actions/create',
        name: 'oto-actions-create',
        component: CampaignsManagementEdit,
        props: { mode: 'create' },
        meta: {
          title: 'campaign-new',
          breadcrumb: [
            { name: '360.index' },
            { name: '360.campaigns.new-title', link: '/oto/actions' },
            { name: '360.campaigns.create' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', 'campaign-new')) next();
          else next('/');
        },
      },
      {
        path: 'oto/campaigns/edit/:id',
        name: 'oto-actions-edit-old',
        component: CampaignsManagementEdit,
        props: route => ({ mode: 'edit', id: route.params.id }),
        meta: {
          title: '360-campaigns',
          breadcrumb: [
            { name: '360.index' },
            { name: '360.campaigns.title', link: '/oto/campaigns' },
            { name: '360.campaigns.edit' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', '360-campaigns')) next();
          else next('/');
        },
      },
      {
        path: 'oto/actions/edit/:id',
        name: 'oto-actions-edit',
        component: CampaignsManagementEdit,
        props: route => ({ mode: 'edit', id: route.params.id }),
        meta: {
          title: 'campaign-new',
          breadcrumb: [
            { name: '360.index' },
            { name: '360.campaigns.new-title', link: '/oto/actions' },
            { name: '360.campaigns.edit' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', 'campaign-new')) next();
          else next('/');
        },
      },
      {
        path: 'oto/indicators-old',
        name: '360-indicators-old',
        component: IndicatorsOld,
        meta: {
          title: '360-indicators-old',
          breadcrumb: [{ name: '360.index' }, { name: '360.indicators-old', link: '/oto/indicators-old' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', '360-indicators')) next();
          else next('/');
        },
      },
      {
        path: 'oto/indicators',
        name: '360-indicators',
        component: Indicators,
        meta: {
          title: '360-indicators',
          breadcrumb: [{ name: '360.index' }, { name: '360.indicators', link: '/oto/indicators' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', '360-indicators')) next();
          else next('/');
        },
      },
      {
        path: 'oto/engagement-report',
        name: 'oto-engagement-report',
        component: EngagementReport,
        meta: {
          title: 'oto-engagement-report',
          breadcrumb: [{ name: '360.index' }, { name: '360.engagement-report', link: '/oto/engagement-report' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', 'oto-engagement-report')) next();
          else next('/');
        },
      },
      {
        path: 'oto/sellers',
        name: '360-sellers',
        component: Sellers,
        meta: {
          showModal: false,
          title: '360-sellers',
          breadcrumb: [{ name: '360.index' }, { name: '360.sellers' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', '360-sellers')) next();
          else next('/');
        },
      },
      {
        path: 'oto/sellers/:sellerId',
        name: '360-sellers-detail',
        component: Sellers,
        meta: {
          showModal: true,
          title: '360-sellers',
          breadcrumb: [{ name: '360.index' }, { name: '360.sellers' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', '360-sellers')) next();
          else next('/');
        },
      },
      {
        path: 'oto/push',
        name: '360-push',
        component: PushNotifications,
        meta: {
          showModal: false,
          title: '360-push',
          breadcrumb: [{ name: '360.index' }, { name: '360.push-notifications' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', '360-push')) next();
          else next('/');
        },
      },
      {
        path: 'oto/push/create',
        name: '360-push-create',
        props: () => ({ mode: 'create' }),
        component: PushNotificationsDetail,
        meta: {
          showModal: false,
          title: '360-push-create',
          breadcrumb: [
            { name: '360.index' },
            { name: '360.push-notifications', link: '/oto/push' },
            { name: '360.push-notifications-create' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', '360-push')) next();
          else next('/');
        },
      },
      {
        path: 'oto/push/edit/:id',
        name: '360-push-edit',
        component: PushNotificationsDetail,
        props: route => ({ mode: 'edit', id: route.params.id }),
        meta: {
          title: '360-push-edit',
          breadcrumb: [
            { name: '360.index' },
            { name: '360.push-notifications', link: '/oto/push' },
            { name: '360.push-notifications-edit' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', '360-push')) next();
          else next('/');
        },
      },
      {
        path: 'oto/push/view/:id',
        name: '360-push-detail',
        component: PushNotificationsDetail,
        props: route => ({ mode: 'view', id: route.params.id }),
        meta: {
          title: '360-push-detail',
          breadcrumb: [
            { name: '360.index' },
            { name: '360.push-notifications', link: '/oto/push' },
            { name: '360.push-notifications-detail' },
          ],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', '360-push')) next();
          else next('/');
        },
      },
      {
        path: 'oto/attachments',
        name: 'oto-attachments',
        component: ActionsAttachments,
        meta: {
          showModal: false,
          title: 'oto-attachments',
          breadcrumb: [{ name: '360.index' }, { name: '360.oto-attachments' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', 'oto-attachments')) next();
          else next('/');
        },
      },
      {
        path: 'oto/messages',
        name: 'oto-messages',
        component: ActionsMessages,
        meta: {
          showModal: false,
          title: 'oto-messages',
          breadcrumb: [{ name: '360.index' }, { name: '360.oto-messages' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', 'oto-messages')) next();
          else next('/');
        },
      },
      {
        path: 'oto/sales-report',
        name: 'oto-sales-report',
        component: SalesReport,
        meta: {
          showModal: false,
          title: 'oto-sales-report',
          breadcrumb: [{ name: '360.index' }, { name: '360.oto-sales-report' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', 'oto-sales-report')) next();
          else next('/');
        },
      },
      {
        path: 'oto/sales-report-new',
        name: 'sales-report-new',
        component: SalesReportNew,
        meta: {
          showModal: false,
          title: 'oto-sales-report-new',
          breadcrumb: [{ name: '360.index' }, { name: '360.oto-sales-report-new' }],
        },
        beforeEnter: (to, from, next) => {
          if (hasMatchSubRoutes('360', 'sales-report-new')) next();
          else next('/');
        },
      },
      // End oto

      // Start cashback
      {
        path: 'cashback/cashback',
        name: 'cashback-cashback',
        component: CashbackDetail,
        meta: {
          title: 'cashback-cashback',
          breadcrumb: [{ name: 'cashback.index' }, { name: 'cashback.cashback' }],
        },
        beforeEnter: (_to, _from, next) => {
          if (hasMatchSubRoutes('cashback', 'cashback-cashback')) next();
          else next('/');
        },
      },
      {
        path: 'cashback/cashback-report',
        name: 'cashback-report',
        component: CashbackReport,
        meta: {
          showModal: false,
          title: 'cashback-report',
          breadcrumb: [{ name: 'cashback.index' }, { name: 'cashback.cashback-report' }],
        },
        beforeEnter: (_to, _from, next) => {
          if (hasMatchSubRoutes('cashback', 'cashback-report')) next();
          else next('/');
        },
      },
      // End cashback

      // Start settings
      {
        path: 'settings',
        name: 'Settings',
        component: Settings,
        children: [
          {
            path: '',
            name: 'adm-preferences-root',
            component: Preferences,
            meta: {
              title: 'settings',
              breadcrumb: [{ name: 'settings.preferences' }],
            },
          },
          {
            path: 'preferences',
            name: 'adm-preferences',
            component: Preferences,
            meta: {
              title: 'settings',
              breadcrumb: [{ name: 'settings.preferences' }],
            },
          },
          {
            path: 'users',
            name: 'adm-users',
            component: Users,
            meta: {
              title: 'settings',
              breadcrumb: [{ name: 'settings.users' }],
            },
            beforeEnter: (to, from, next) => {
              if (hasMatchSubRoutes('settings', 'adm-users')) next();
              else next('/settings');
            },
            children: [
              {
                path: 'create',
                name: 'UserCreate',
                component: Users,
                meta: { isModal: true, title: 'settings' },
              },
              {
                path: 'edit/:id',
                name: 'UserEdit',
                component: Users,
                meta: { isModal: true, title: 'settings' },
              },
            ],
          },
          {
            path: 'metrics',
            name: 'adm-metrics',
            component: Metrics,
            meta: {
              title: 'settings',
              breadcrumb: [{ name: 'settings.metrics' }],
            },
            beforeEnter: (to, from, next) => {
              if (hasMatchSubRoutes('settings', 'adm-metrics')) next();
              else next('/settings');
            },
          },
          {
            path: 'stores',
            name: 'adm-stores',
            component: StoreSettings,
            meta: {
              title: 'settings',
              breadcrumb: [{ name: 'settings.stores.table' }],
            },
            beforeEnter: (to, from, next) => {
              if (hasMatchSubRoutes('settings', 'adm-stores')) next();
              else next('/settings');
            },
            children: [
              {
                path: '',
                name: 'adm-stores',
                component: StoreSettingsTable,
                props: { mode: 'create' },
                meta: {
                  title: 'settings',
                  breadcrumb: [
                    { name: 'settings.stores.table', link: '/settings/stores' },
                    { name: 'settings.stores.create' },
                  ],
                },
              },
              {
                path: 'create',
                name: 'adm-stores-create',
                component: StoresSettingsRegister,
                props: { mode: 'create' },
                meta: {
                  title: 'settings',
                  breadcrumb: [
                    { name: 'settings.stores.table', link: '/settings/stores' },
                    { name: 'settings.stores.create' },
                  ],
                },
              },
              {
                path: 'edit/:id',
                name: 'adm-stores-edit',
                component: StoresSettingsRegister,
                props: route => ({ mode: 'edit', id: route.params.id }),
                meta: {
                  title: 'settings',
                  breadcrumb: [
                    { name: 'settings.stores.table', link: '/settings/stores' },
                    { name: 'settings.stores.edit' },
                  ],
                },
              },
              {
                path: ':id',
                name: 'adm-stores-duplicate',
                component: StoresSettingsRegister,
                props: route => ({ id: route.params.id }),
                meta: {
                  title: 'settings',
                  breadcrumb: [
                    { name: 'settings.stores.table', link: '/settings/stores' },
                    { name: 'settings.stores.duplicate' },
                  ],
                },
              },
            ],
          },
          {
            path: 'security',
            name: 'adm-security',
            component: Security,
            meta: {
              title: 'settings',
              breadcrumb: [{ name: 'settings.security' }],
            },
          },
          {
            path: 'integrations',
            name: 'adm-integrations',
            component: Integrations,
            props: route => ({ state: route.query.state }),
            meta: {
              title: 'settings',
              breadcrumb: [{ name: 'settings.integrations' }],
            },
            beforeEnter: (to, from, next) => {
              if (hasMatchSubRoutes('settings', 'adm-integrations')) next();
              else next('/settings');
            },
            children: [
              {
                path: 'create',
                name: 'CreateIntegration',
                component: IntegrationCreateEdit,
                meta: { isModal: true },
              },
              {
                path: 'edit/:id',
                name: 'Editintegration',
                component: IntegrationCreateEdit,
                meta: { isModal: true },
              },
              {
                path: ':id',
                name: 'IntegrationState',
                component: Integrations,
              },
            ],
          },
          {
            path: 'limits',
            name: 'adm-limits',
            component: Limits,
            meta: {
              title: 'settings',
              breadcrumb: [{ name: 'settings.limits' }],
            },
            beforeEnter: (to, from, next) => {
              if (hasMatchSubRoutes('settings', 'adm-limits')) next();
              else next('/settings');
            },
          },
          {
            path: 'custom-fields',
            name: 'adm-custom-fields',
            component: CustomFields,
            meta: {
              title: 'settings',
              breadcrumb: [{ name: 'settings.custom-fields' }],
            },
            beforeEnter: (to, from, next) => {
              if (hasMatchSubRoutes('settings', 'adm-custom-fields')) next();
              else next('/settings');
            },
          },
        ],
      },
      // End settings
      {
        path: 'glossary',
        name: 'glossary',
        component: Glossary,
        meta: {
          title: 'glossary',
          breadcrumb: [{ name: 'glossary' }],
        },
      },
    ],
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        store.dispatch('logout');
      } else if (store.getters.ready || store.getters.ready === null) {
        next();
      }
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginOto,
    meta: {
      title: 'login',
    },
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) {
        next('/');
      } else {
        next();
      }
    },
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: (to, from, next) => {
      // Check if federation is set
      let logoutRedirect;
      if (store.getters.user) {
        const { federation } = store.getters.user;
        if (federation && federation.logoutUrl) {
          logoutRedirect = federation.logoutUrl;
        }
      }

      store.dispatch('logout');

      // Sign out user from SSO
      if (logoutRedirect) {
        window.location.href = logoutRedirect;
      }

      next('/login');
    },
  },
  {
    path: '/:catchAll(.*)',
    beforeEnter: (to, from, next) => {
      next('/login');
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    else if (!to.meta.isModal && !from.meta.isModal) return { x: 0, y: 0 };
    return false;
  },
});

export default router;
